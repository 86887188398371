function setupColumnFiltering(dashboardTable) {
    // Set filters for each column, instead of using a table wide filter
    datatablesExtensions.addMultiColumnFiltering(dashboardTable);

    // Add our custom 
    datatablesExtensions.addCustomServerSideFilter(dashboardTable, '.customSearch-checkbox');
}
/* Tell JsHint about the other JS libraries so we don't get warnings*/
/*global commonDialogs */
/*global autocompleteMVC */
/*global datatablesExtensions */

/* managerBase.js
   The script file included in every page.
   It should be used for re-usable functions that could be useful on a number of pages or for scripting needed in the layout page.
*/

var managerBase = (function () {
    /**
    * Sets the search fields in a table header to call the given function after a delay once the search fields are changed
    * @param {function} searchCallback The function to call when a search field is changed, after a delay
    */
    function setTableSearchFunction(searchCallback) {
        // Set a delay on typing into the search fields and actually doing the search
        var delayedSearch = 0;
        $('.tableSearchText').keyup(function () {
            clearTimeout(delayedSearch);
            delayedSearch = setTimeout(function () { searchCallback(); }, 500);
        });

        // Make sure to do the search on page load if there is any text in the search fields, as refreshing or the back button can fill these fields in.
        $('.tableSearchText').each(function () {
            if ($(this).val().length > 0) {
                searchCallback();
                return false; // break out of the loop
            }
        });
    }

    /**
    * Sets the given function to be called when the user scrolls to the bottom of the page.
    * @param {function} scrollCallback The function to call when the user scrolls to the bottom of the page
    */
    function onScrollNearButtom(scrollCallback) {
        // On scroll, load the next N side effects and append them to the bottom
        $(window).scroll(function () {
            if ($(window).scrollTop() === $(document).height() - $(window).height()) {
                console.log('Scrolled to bottom');
                scrollCallback();
            }
        });
    }

    /**
    * Loads the option elements in dropdowns matching the selector with the data retreived from the json call
    * @param {string} jsonURL The url to a json method that returns a List<SelectListItem>
    * @param {string} dropdownSelector A jquery selector for the dropdowns that are being changed
    */
    function loadDropdownOptions(jsonURL, dropdownSelector) {
        // Reload all the values in the dropdown, to get the newly added value
        $.getJSON(jsonURL, function (data) {

            $(dropdownSelector).each(function () {
                var selectedValue = $(this).val();
                // We don't get an empty option element from the json method, so we need to keep track of if
                // the dropdown already has an empty option and manually add it back in
                var hasEmptyOption = $(this).children('option[value=""]').length > 0;

                var result = '';
                if (hasEmptyOption)
                    result += '<option value=""></option>';

                for (var i = 0; i < data.length; ++i) {
                    result += '<option value="' + data[i].Value + '">' + data[i].Text + '</option>';
                }

                $(this).html(result);
                // Set the selected value back to what it was
                $(this).val(selectedValue);
            });
        });
    }

    function openBootstrapTab(tabHref) {
        // Remove the # symbol if we have one.
        var poundIndex = tabHref.indexOf('#');
        if (poundIndex === -1)
            tabHref = '#' + tabHref;

        $('a[href="' + tabHref + '"]').click();
    }

    return {
        setTableSearchFunction: setTableSearchFunction,
        onScrollNearButtom: onScrollNearButtom,
        loadDropdownOptions: loadDropdownOptions,
        openBootstrapTab: openBootstrapTab,
    };
})();
/* Tell JsHint about the other JS libraries so we don't get warnings*/
/*global commonDialogs */
/*global autocompleteMVC */
/*global datatablesExtensions */

$(function () {
    $('#tabs li a').each(function () {
        var tabPaneId = $(this).attr('href');
        if ($(tabPaneId).find('.field-validation-error').length > 0) {
            $(this).addClass('tabHasError');
            var test = $(this);
        }
    });

    // If the user changes a record, then set the IsModified flag in the model to True so we can only save the records that
    // are modified.
    $('input, select, textarea').on('change', function (e) {
        editBase.setIsModified($(this));
    });
});

var editBase = (function () {
    /**
    * Flags this record as modified
    * @param {elementChanged} The element that was changed to cause the modification
    */
    function setIsModified(elementChanged) {
        // If this element is in a table row, then look for the isModified element in a <td>
        // If it's not in a row, look for the isModified element in the tab-pane
        if ($(elementChanged).parents('tr').length > 0)
            $(elementChanged).parents('td').siblings().addBack().children('.isModified').val('True');
        else if ($(elementChanged).parents('.tab-pane').length > 0)
            $(elementChanged).parents('.tab-pane').children('.isModified').val('True');
        else
            $(elementChanged).parents('.edit-form').children('.isModified').val('True');
    }

    /**
    * Returns true if the user made any changes to any records on the screen
    * @return {Boolean} True if the user made any changes to any records on the screen, false otherwise
    */
    function hasAnyModifications() {
        var hasModifications = false;
        $('.isModified').each(function () {
            if ($(this).val() === 'True')
                hasModifications = true;
        });
        return hasModifications;
    }

    /**
    * Toggles the isDeleted hidden field in a row when the Delete button is pressed.
    * If the row is newly added by the user and doesn't exist in the database, the row is deleted outright
    */
    function toggleDeletedRow(deleteRowButton) {
        // Toggle the hidden isDeleted flag
        var hiddenDeletedField = $(deleteRowButton).parents('td').siblings().addBack().children('.isDeleted');
        var hiddenIsNewField = $(deleteRowButton).parents('td').siblings().addBack().children('.isNew');
        var tableRow = $(deleteRowButton).parents('tr');

        if (hiddenDeletedField.val() === 'True') {
            hiddenDeletedField.val('False'); // Set this record to not deleted
        } else {
            hiddenDeletedField.val('True'); // Set this record to be deleted
            // If this record is new, then just delete the entire row
            if (hiddenIsNewField.val() === 'True')
                $(tableRow).remove();
        }

        // Flag the record as modified
        editBase.setIsModified($(deleteRowButton));

        setDeletedRows(hiddenDeletedField);
    }

    /*
    * Sets any rows that are marked as deleted to be colored grey and say "Cancel Delete".
    * Called on document.ready so a browser refresh or back button press doesn't wipe out the deleted colors and text
    */
    function setDeletedRows(hiddenDeletedFields) {
        hiddenDeletedFields.each(function (index, element) {
            var tableRow = $(element).parents('tr');

            // If the row is not deleted, enable all the fields and set the text to 'Delete'
            if ($(element).val() === 'False') {
                // Set this record to not deleted
                //element.val('False');
                $(tableRow).find('.deleteRowButton').text('Delete');
                // Enable all the editable fields in the row
                tableRow.find('input[type=text], textarea').not(':hidden').attr('readonly', false);
                tableRow.find('input:not(input[type=text]), select').not(':hidden').prop('disabled', false);
                // Remove the color
                tableRow.removeClass('rowWasDeleted');
            } else {
                // If the row is deleted, disable all the fields in the row and set the text to 'Cancel delete'
                // Set this record to be deleted
                //element.val('True');
                $(tableRow).find('.deleteRowButton').text('Cancel Delete');
                // Disable all the editable fields in the row
                tableRow.find('input[type=text], textarea').not(':hidden').attr('readonly', true);
                tableRow.find('input:not(input[type=text]), select').not(':hidden').prop('disabled', true);
                // Color the row to indicate that it was deleted
                tableRow.addClass('rowWasDeleted');
            }
        });
    }

    /**
    * Sets the delete row button functionality on all the delete buttons
    */
    function setDeleteRowButtons() {
        // If the delete button is clicked, then flag the row as deleted and modified
        $('body').on('click', '.deleteRowButton', function () {
            editBase.toggleDeletedRow($(this));
            return false; // Stop the normal click behavior
        });
    }

    function insertTextAtCursor(textField, textToInsert) {
        // IE support
        if (document.selection) {
            textField.focus();
            var sel = document.selection.createRange();
            sel.text = textToInsert;

        }
        // MOZILLA and others
        else if (textField.selectionStart || textField.selectionStart == '0') {
            var startPos = textField.selectionStart;
            var endPos = textField.selectionEnd;
            textField.value = textField.value.substring(0, startPos) + textToInsert + textField.value.substring(endPos, textField.value.length);

            // Reset the cursor position back to where it was.
            textField.selectionStart = startPos + textToInsert.length;
            textField.selectionEnd = startPos + textToInsert.length;
        } else {
            textField.value += textToInsert;
        }

        // Changing the text in javascript DOES NOT trigger the jQuery change event. We need to forcibly trigger it here.
        $(textField).trigger('change');
    }

    // Add a hidden field for the finalize button so that it gets posted back. We unfortunately cannot do this with jquery.submit
    function addFormFinalizeButton(form) {
        $("#formFinalizeButton", form).eq(0).each(function () {
            var self = $(this);
            var tempElement = $("<input type='hidden'/>");

            // clone the important parts of the button used to submit the form.
            tempElement
                .attr("name", this.name)
                .val(self.val())
                .appendTo(form);
        });
    }

    return {
        setIsModified: setIsModified,
        hasAnyModifications: hasAnyModifications,
        insertTextAtCursor: insertTextAtCursor,
        toggleDeletedRow: toggleDeletedRow,
        setDeletedRows: setDeletedRows,
        setDeleteRowButtons: setDeleteRowButtons,
        addFormFinalizeButton: addFormFinalizeButton
    };
})();